import React from 'react';
import { getResponsiveProp } from '@parsleyhealth/cilantro-ui';
import {
  BarChart,
  ContinuedTextListModule,
  FeaturedArticles,
  HalfAndHalfStats,
  Hero,
  Module,
  SingleQuote,
  StatisticStructuredBox,
  Structured,
  Text,
  TextLockup
} from '../../redesign';
import { CliniciansHalfAndHalf } from '../../redesign/pre-built-components';
import { IllustrativeIcon } from '../../redesign/molecules/icons';
import { Layout } from '../../redesign';

import halfAndHalfTitle from '../../redesign/content/unwell-and-undiagnosed/half-and-half-title.json';
import structuredStatistics from '../../redesign/content/unwell-and-undiagnosed/structured-statistics.json';
import continuedTextList from '../../redesign/content/unwell-and-undiagnosed/continued-text-list.json';
import structured from '../../redesign/content/unwell-and-undiagnosed/structured.json';
import halfAndHalfStatistic from '../../redesign/content/unwell-and-undiagnosed/half-and-half-statistic.json';
import singleQuote from '../../redesign/content/unwell-and-undiagnosed/single-quote.json';
import featuredArticles from '../../redesign/content/unwell-and-undiagnosed/featured-articles.json';

export default function UnwellUndiagnosed() {
  const structuredItems = structured.items.map((item: any) => {
    return {
      ...item,
      illustration: (
        <IllustrativeIcon color="sage" iconWidth={128} name={item.icon} />
      )
    };
  });
  const statElement = (
    <BarChart
      textVariant="p3a"
      title={halfAndHalfStatistic.statisticTitle}
      name={halfAndHalfStatistic.yAxis}
      data={halfAndHalfStatistic.data}
    />
  );
  return (
    <Layout>
      <Module>
        <Hero {...halfAndHalfTitle} />
      </Module>
      <Module marginTop="lg">
        <StatisticStructuredBox {...structuredStatistics} />
      </Module>
      <Module>
        <ContinuedTextListModule {...continuedTextList} />
      </Module>
      <Module>
        <Structured
          heading={structured.heading}
          description={structured.description}
          items={structuredItems}
          alignment="center"
        />
      </Module>
      <Module>
        <HalfAndHalfStats
          stats={statElement}
          reversed={halfAndHalfStatistic.statPosition}
        >
          <TextLockup alignment={getResponsiveProp('center', 'left')}>
            <Text variant="h2a" className="hero-headline">
              {halfAndHalfStatistic.title}
            </Text>
            <Text variant="p2a" className="hero-description">
              {halfAndHalfStatistic.description}
            </Text>
          </TextLockup>
        </HalfAndHalfStats>
      </Module>
      <Module>
        <SingleQuote {...singleQuote} />
      </Module>
      <Module>
        <CliniciansHalfAndHalf
          ctaTrackingLabel="Meet Our Clinicians button on the Unexplained Illness page - Stress-free online holistic care module"
          eyebrow="Diagnose your symptoms."
        />
      </Module>
      <Module>
        <FeaturedArticles {...featuredArticles} />
      </Module>
    </Layout>
  );
}
